import _extends from "@babel/runtime/helpers/esm/extends";
import { offsetLimitPagination } from '@apollo/client/utilities';
var cachePolicies = {
  Brand: {
    keyFields: ['slug']
  },
  Category: {
    fields: {
      meta: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      },
      subcategories: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      }
    },
    keyFields: function keyFields(object) {
      return (object == null ? void 0 : object.subcategories) ? "".concat(object.id, ":Sub") : "".concat(object.id, ":NoSub");
    }
  },
  FilterValueResponse: {
    keyFields: function keyFields(object) {
      return object.type ? ['slug', 'id', 'type'] : ['slug', 'id'];
    }
  },
  ListConversations: {
    fields: {
      listConversations: offsetLimitPagination()
    }
  },
  Product: {
    fields: {
      seller: {
        details: {
          address: {
            merge: function merge(existing, incoming) {
              return _extends({}, existing, incoming);
            }
          }
        }
      }
    },
    keyFields: function keyFields(object) {
      var _object$ads$id, _object$ads;
      return "".concat(object.variationId, ":").concat(object.id, ":").concat((_object$ads$id = object == null ? void 0 : (_object$ads = object.ads) == null ? void 0 : _object$ads.id) !== null && _object$ads$id !== void 0 ? _object$ads$id : '');
    }
  },
  Query: {
    fields: {
      recommendation: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      }
    }
  },
  Recommendation: {
    keyFields: ['id', 'title']
  },
  Variation: {
    keyFields: function keyFields(object) {
      return "".concat(object.id, ":").concat(object.type);
    }
  }
};
export default cachePolicies;